import React, { Component } from "react";
import Img from "gatsby-image"
import styles from "./posts.module.css"
import Button from "../buttons/button"

class PostPreview extends Component {
    formattedDate() {
        let date = new Date(this.props.postData.date)
        return date.getDate() + '.' + date.getMonth() + "." + date.getFullYear()
    }

    render() {
        return <div className={styles.preview + " card"}>
            <div className={styles.imgWrapper}>
                <Img fluid={this.props.postData.featured_media.localFile.childImageSharp.fluid} />
                <div className={styles.categories}>
                    {this.props.postData.categories.map(cat => {
                        return <div className={styles.category}>{cat.name}</div>
                    })}
                </div>
            </div>
            <div className={styles.content}>

                <span className={styles.date} dangerouslySetInnerHTML={{ __html: this.formattedDate() }} />
                <h2 dangerouslySetInnerHTML={{ __html: this.props.postData.title }} />
                <span className={styles.excerpt} dangerouslySetInnerHTML={{ __html: this.props.postData.excerpt }} />
                <span className={styles.author} dangerouslySetInnerHTML={{ __html: this.props.postData.author.name }} />
                <div className={styles.actions}>
                    <Button buttonText="Weiter lesen" link={"/post/" + this.props.postData.path} />
                </div>
            </div>
        </div>
    }
}

export default PostPreview