import React, { Component } from "react";
import { graphql } from "gatsby"
import styles from "../styles/blog.module.css"

import PostPreview from "../components/post/preview"

class Blog extends Component {
    render() {
        return <div className="main-content">
            <div className={styles.blog}>
                {this.posts()}
            </div>
        </div>
    }

    posts() {
        let posts = []
        for (const post of this.props.data.allWordpressPost.edges) {
            posts.push(
                <PostPreview postData={post.node} />
            )
        }
        return posts;
    }
}

export default Blog

export const pageQuery = graphql`
    query blogPageQuery {
        allWordpressPost {
            edges {
              node {
                id
                title
                excerpt
                date
                path
                author{
                    name
                }
                categories{
                    name
                }
                featured_media{
                    localFile{
                      childImageSharp{
                        fluid(maxWidth: 500) {
                            ...GatsbyImageSharpFluid
                          }
                      }
                    }
                  }
              }
            }
          }
    }
`
